import React from "react"
import SEO from "../components/seo"
import Form from "../components/Form"
import home from "../images/home.jpg"
import Grid from "@material-ui/core/Grid"
import contactStyles from "./contact.module.scss"

const Contact = () => (
  <>
    <SEO
      title="Contacto"
      description="Contáctanos a través de nuestro formulario, correo contaco@ppdc.mx o teléfono: +52 1 55 8662 5560."
      image={`https://www.ppdc.mx${home}`}
      url="/contact"
    />
    <Grid container className={contactStyles.grid}>
      <Grid item sm={12} md={6} className={contactStyles.gridBackground}></Grid>
      <Grid item xs={12} sm={12} md={6} className={contactStyles.formContainer}>
        <Form />
      </Grid>
    </Grid>
  </>
)

export default Contact
